html {
  /*font-size:62.5%;*/
  font-size: 16px;
}

body {
  background-color:transparent;
  /*font-size:1.6em;*/
}

.cw-c-pointer {
  cursor:pointer;
}
